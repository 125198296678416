(function($, OWX) {

  (function(CE) {

    CE.Faq = {

      init: function() {
        this.bindUI();
      },

      deinit: function() {
        this.unbindUI();
      },

      bindUI: function() {

        var that = this;

        $('body .owx-faq-group').on('click','.nav-close',function(e){
          // close
          var $item=$(e.target).closest('.owx-list-item');
          $item.find('.owx-list-item-content').animate({
            height:'0'
          },300, function(){
            $item.addClass('closed');
          });
        });

        $('body .owx-faq-group').on('click','.nav-open',function(e){
          // open
          var $item=$(e.target).closest('.owx-list-item');
          var height=$item.find('.owx-list-item-content-inner').height();
          $item.find('.owx-list-item-content').animate({
            height: height+'px'
          },300, function(){
            $item.find('.owx-list-item-content').height('auto');
            $item.removeClass('closed');
          });
        });



      },

      unbindUI: function() {

        var that = this;


      }

    };

  })(OWX.CE = OWX.CE || {});

})(jQuery, OWX);
