(function($, OWX) {

  (function(CE) {

    CE.Fees = {

      init: function() {
        this.bindUI();
      },

      deinit: function() {
        this.unbindUI();
      },

      bindUI: function() {

        var that = this;

      function numberWithThousands(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }

      var have_rates = function(data) {
        console.log(data.rates);
        var feeEUR,feeUSD,feeGBP;
        $('.fee-item').each(function(index,element){
          feeEUR=$(element).data('fee');
          feeUSD=50*Math.ceil(feeEUR*data.rates.USD/50.0);
          feeGBP=50*Math.ceil(feeEUR*data.rates.GBP/50.0);
          $(element).find('.fee-item-gbp').html(numberWithThousands(Math.floor(feeGBP))+',-');
          $(element).find('.fee-item-usd').html(numberWithThousands(Math.floor(feeUSD))+',-');
        });
      };

      $.getJSON("https://api.fixer.io/latest?base=EUR&symbols=USD,GBP", have_rates);


      },

      unbindUI: function() {

        var that = this;


      }

    };

  })(OWX.CE = OWX.CE || {});

})(jQuery, OWX);
