
(function($, OWX) {

  (function(CE) {

    CE.Gallery = {

      init: function() {
        this.bindUI();
      },

      deinit: function() {
        this.unbindUI();
      },

      bindUI: function() {

        var that = this;


        /*
        * Ladies Gallery
        */

        var ladies_gallery_filter='*';

    		$grid=$('.ladies-gallery').isotope({
    		  itemSelector: '.ladies-gallery-item'
    		});
        $grid.imagesLoaded( function(){
              $grid.isotope('layout');
              setTimeout(function(){
                OWX.CE.Backtotop.recompute();
              },1000);
        });


    		// filter items on button click
    		$('.ladies-gallery-filter').on( 'click', 'button', function() {
    		  //ladies_gallery_filter = $(this).attr('data-filter');
    		 // $grid.isotope({ filter: ladies_gallery_filter });


          $('.ladies-galleries-visible').empty();

          var id=$(this).data('id');
          var $new_gallery=$('.ladies-galleries-invisible .ladies-gallery-wrapper[data-id="'+id+'"]').clone();
          $new_gallery.find('img').each(function(index){
            $(this).attr('src', $(this).data('src'));
          });
          $('.ladies-galleries-visible').append($new_gallery);

          $grid=$('.ladies-gallery').isotope({
          itemSelector: '.ladies-gallery-item'
        });
        $grid.imagesLoaded( function(){
              $grid.isotope('layout');
              setTimeout(function(){
                OWX.CE.Backtotop.recompute();
              },1000);
        });

          $(this).closest('.filter-button-group').find('button').removeClass('active');
          $(this).addClass('active');
    		});

        /*
        * Ladies Lightbox / Slider
        */

        $('.ladies-gallery-slideshow').on('init', function(event, slick){
          
        });
        


        $('body').on('click','.ladies-gallery-item-show',function(e){
          var $item=$(e.target).closest('.ladies-gallery-item');



          var index=$('.ladies-gallery-item'+ladies_gallery_filter).index($item);
          $(e.target).closest('.ladies-gallery-wrapper').find('.ladies-gallery-slideshow').addClass('on').slick({
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            centerMode: false,
            variableWidth: false,
            //prevArrow: '<div class="slick-nav slick-prev">&lt;</div>',
            //nextArrow: '<div class="slick-nav slick-next">&gt;</div>',
            prevArrow: '.fe-prev',
            nextArrow: '.fe-next',
            dots: false,
            initialSlide: index,
            customPaging: function (slick, index) {
              return '<span class="dot dot-' + index + '"></span>';
            },
          }).slick('slickFilter',ladies_gallery_filter);
        });


        $('body').on('click','.ladies-gallery-slideshow .fe-close', function(e){
          $('.ladies-gallery-slideshow').removeClass('on').slick('unslick');
        });




        /*
        * News Gallery
        */
        
        $grid_news=$('.news-gallery').isotope({
          itemSelector: '.news-gallery-item'
        });
        $grid_news.imagesLoaded( function(){
              $grid_news.isotope('layout');
              setTimeout(function(){
                OWX.CE.Backtotop.recompute();
              },1000);
        });


        $('body').on('click','.owx-news-group button.more-items', function(e){
          var $this=$(e.target);
          var url=$($this.data('pagination')+' a.next').attr('href');

          $.ajax({
             url: url,
             dataType: 'html',
             type: 'post'
          }).done(function(html){
            var $new_items=$(html).find($this.data('items')+' .owx-list-item');
            $grid_news.isotope('insert',$new_items);

            var $new_pagination=$(html).find($this.data('pagination'));

            if($new_pagination.find('a.next').length===0){
              $this.closest('.owx-list-group').find('.more-items-wrapper').remove();
            }else{
              $($this.data('pagination')).replaceWith($new_pagination);
            }

            $grid_news.imagesLoaded( function(){
              $grid_news.isotope('layout');
              setTimeout(function(){
                OWX.CE.Backtotop.recompute();
              },1000);
            });
          });
        });



      },

      unbindUI: function() {

        var that = this;


      }

    };

  })(OWX.CE = OWX.CE || {});

})(jQuery, OWX);




