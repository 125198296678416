(function($, OWX) {

  (function(CE) {

    CE.Backtotop = {

      viewportHeight:0,
      maxY:0,

      init: function() {
        this.viewportHeight=$(window).height();
        this.maxY=$(document).height()-$('.owx-bottomcontainer-wrapper').height();



        if ($(window).scrollTop() < this.viewportHeight*1.5) {
          $('.backtotop-button').hide();
        }


        this.bindUI();
      },

      deinit: function() {
        this.unbindUI();
      },

      recompute: function(){
        var that=this;
            that.viewportHeight=$(window).height();
            that.maxY=$(document).height()-$('.owx-bottomcontainer-wrapper').height();

            if ($(window).scrollTop() > that.viewportHeight*1.5) {
                $('.backtotop-button').fadeIn();
            } else {
                $('.backtotop-button').fadeOut();
            }


            if($(window).scrollTop()+that.viewportHeight>that.maxY){
              var pos=$(window).scrollTop()+that.viewportHeight-that.maxY;
              $('.backtotop-wrapper').css('bottom',pos+'px');
            }else{
              $('.backtotop-wrapper').css('bottom','0');
            }
      },

      bindUI: function() {

        var that = this;

        $('body').on('click','.backtotop-button', function(e){
          e.preventDefault();
          $('html,body').animate({
            scrollTop: 0
          }, 2000);         
        });

        $(window).scroll(function(){
            that.recompute();
            
        });

        $(window).scroll(function(){
          
        });




      },

      unbindUI: function() {

        var that = this;


      }

    };

  })(OWX.CE = OWX.CE || {});

})(jQuery, OWX);
