var OWX = {};

(function($) {

  OWX = {
    init : function() {
      for (var ce in this.CE) {
        if (this.CE.hasOwnProperty(ce)) {
          this.CE[ce].init();
        }
      }
    },
    deinit : function() {
      for (var ce in this.CE) {
        if (this.CE.hasOwnProperty(ce)) {
          this.CE[ce].deinit();
        }
      }
    },
    initFrontendEditing : function() {
      $('body').on('owx-fedit-saved-ce', function(e){
        OWX.deinit();
        OWX.init();
      });
    },
    CE : {}
  };

  $(function () {
    OWX.init();
    OWX.initFrontendEditing();
  });

  $(window).on('owx-fedit-saved-ce', function(e) {
    OWX.deinit();
    OWX.init();
  });
  
})(jQuery);
