(function($, OWX) {

  (function(CE) {

    CE.Feedback = {

      init: function() {
        this.bindUI();
      },

      deinit: function() {
        this.unbindUI();
      },

      bindUI: function() {

        var that = this;

        $('.ladies-feedback-slider').slick({
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
          adaptiveHeight: true,
          arrows:true,
          swipe: true,
          touchMove: false,
          prevArrow: '<div class="slick-nav slick-prev"></div>',
          nextArrow: '<div class="slick-nav slick-next"></div>',
          dots: false,
          
          customPaging: function (slick, index) {
            return '<span class="dot dot-' + index + '"></span>';
          },
        });
      },

      unbindUI: function() {

        var that = this;

        $('.owx-gallery.owx-layout-default.slick-initialized').slick('unslick');

      }

    };

  })(OWX.CE = OWX.CE || {});

})(jQuery, OWX);
